import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page describes Data Migrators’ policy on the use of removable media.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Overview</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Purpose</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Scope</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy Compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Exceptions</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Non-compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Related Documents</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Definitions and Terms</AnchorLink>
    </AnchorLinks>
    <h2>{`Overview`}</h2>
    <p>{`Removable media is a well-known source of malware infections and has
been directly tied to the loss of sensitive information in many
organizations.`}</p>
    <h2>{`Purpose`}</h2>
    <p>{`The purpose of this policy is to minimize the risk of loss or exposure
of sensitive information maintained by Data Migrators and to reduce
the risk of acquiring malware infections on computers operated by
Data Migrators.`}</p>
    <h2>{`Scope`}</h2>
    <p>{`This policy covers all computers and servers operating in Data Migrators.`}</p>
    <h2>{`Policy`}</h2>
    <p>{`Data Migrators staff may only use Data Migrators removable media in
their work computers. Data Migratorsremovable media may not be
connected to or used in computers that are not owned or leased by the
Data Migrators without explicit permission of the Data Migrators
InfoSec staff. Sensitive information should be stored on removable media
only when required in the performance of your assigned duties or when
providing information required by other state or federal agencies. When
sensitive information is stored on removable media, it must be encrypted
in accordance with the Data Migrators `}<em parentName="p">{`Acceptable Encryption Policy`}</em>{`.`}</p>
    <p>{`Exceptions to this policy may be requested on a case-by-case basis by
Data Migrators-exception procedures.`}</p>
    <h2>{`Policy Compliance`}</h2>
    <h3>{`Compliance Measurement`}</h3>
    <p>{`The Infosec team will verify compliance to this policy through various
methods, including but not limited to, periodic walk-thrus, video
monitoring, business tool reports, internal and external audits, and
feedback to the policy owner.`}</p>
    <h2>{`Exceptions`}</h2>
    <p>{`Any exception to the policy must be approved by the Infosec team in
advance.`}</p>
    <h2>{`Non-Compliance`}</h2>
    <p>{`An employee found to have violated this policy may be subject to
disciplinary action, up to and including termination of employment.`}</p>
    <h2>{`Related Documents`}</h2>
    <ul>
      <li parentName="ul">{`Acceptable Encryption Policy`}</li>
    </ul>
    <h2>{`Definitions and Terms`}</h2>
    <p>{`The following definition and terms can be found in the `}<a parentName="p" {...{
        "href": "https://www.sans.org/security-resources/glossary-of-terms/"
      }}>{`SANS Glossary`}</a>{`:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Encryption`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Malware`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Removable Media`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Sensitive Information`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      